import Swiper from 'swiper/swiper-bundle';
export function tabs() {

  // Таб слайдер
  let tabsItem = $(".modal_tabs__item");
  let tabsContent = $(".modal-tabs-content");

  tabsItem.on("click", function (event) {
    let activeContent = $(this).attr("data-target");
    $(tabsItem).removeClass("modal_tabs--active");
    $(this).addClass("modal_tabs--active");
    $(tabsContent).removeClass("modal-tabs-content--active");
    $(activeContent).addClass("modal-tabs-content--active");
  });


  // Таб сравнения "Снаружи\Изнутри"
  let tabsItem2 = $(".optimal-list__item");
  let tabsContent2 = $(".optimal-twenty__content");

  tabsItem2.on("click", function (event) {
    let activeContent = $(this).attr("data-target");
    $(tabsItem2).removeClass("modal_tabs--active");
    $(this).addClass("modal_tabs--active");
    $(tabsContent2).removeClass("optimal-twenty__content--active");
    $(activeContent).addClass("optimal-twenty__content--active");
  });

}
export function tabsTintingOffices() {
  function sliderInit(item){
    const pagination = new Swiper(item.querySelector('.slider-init .pagination'), {
      spaceBetween: 10,
      slidesPerView: 2,
      freeMode: true,
      watchSlidesProgress: true,
      loop: true,
      breakpoints: {
      561: {
          slidesPerView: 3,
      },
      761: {
          slidesPerView: 4,
      },
      961: {
          slidesPerView: 4,
      }
      }
    });
    const slider = new Swiper(item.querySelector('.slider-init .slider'), {
        spaceBetween: 10,
        loop: true,
        navigation: {
            nextEl: item.querySelector('.slider-init .swiper-button-next'),
            prevEl: item.querySelector('.slider-init .swiper-button-prev'),
          
        },
        thumbs: {
            swiper: pagination,
        },
    });
  }
  const parent = document.querySelectorAll('.films-type');
  parent.forEach(item=>{
    const tabs = item.querySelectorAll('.tabs__element');
	  const content = item.querySelectorAll('.tabs__content');
    const info = item.querySelectorAll('.tabs__info-content');
    const button = item.querySelectorAll('.btn--prev');

    for(let i = 0; i < tabs.length; i++){  
      if(tabs[i]){
        $(tabs[0]).addClass('active');
        $(content[0]).addClass('active');
        $(info[0]).addClass('active');
        $(content[0]).addClass('slider-init');
        $(button[0]).addClass('active');
        tabs[i].addEventListener('click', function(){
          $(item).find('.tabs__element').removeClass('active');
          $(item).find('.tabs__content').removeClass('active');
          $(item).find('.tabs__content').removeClass('slider-init');
          $(item).find('.tabs__info-content').removeClass('active');
          $(item).find('.btn').removeClass('active');
          $(tabs[i]).addClass('active');
          $(content[i]).addClass('active');
          $(content[i]).addClass('slider-init');
          $(info[i]).addClass('active');
          $(button[i]).addClass('active');
          sliderInit(item);
        })
      }
    }
    sliderInit(item);
  })
}
export function tabsPortfolio() {
    const tabs = document.querySelectorAll('.portfolio-tab__switch');
	  const content = document.querySelectorAll('.portfolio-tab__content');
    const allWork = document.querySelector('.portfolio-tab__allWork');
    for(let i = 0; i < tabs.length; i++){  
      if(tabs[i]){
        $(content[i]).addClass('active');
        $(allWork).addClass('active');
        tabs[i].addEventListener('click', function(){
          $(allWork).removeClass('active');
          $('.portfolio-tab__switch').removeClass('active');
          $('.portfolio-tab__content').removeClass('active');
          $(tabs[i]).addClass('active');
          $(content[i]).addClass('active');
        })
        allWork.addEventListener('click', function(){
          $('.portfolio-tab__switch').removeClass('active');
          $(allWork).addClass('active');
          $(content[i]).addClass('active');
        })
      }
    }
}
export function tabsModalInner() {
  const parents = document.querySelector('.films-inner');
  if(parents){
    const tabs = parents.querySelectorAll('.modal-tab__switch');
    const content = parents.querySelectorAll('.modal-tab__content');
    for(let i = 0; i < tabs.length; i++){  
      if(tabs[i]){
        tabs[i].addEventListener('click', function(){
          $(parents).find('.modal-tab__switch').removeClass('active');
          $(parents).find('.modal-tab__content').removeClass('active');
          $(tabs[i]).addClass('active');
          $(content[i]).addClass('active');
        })
      }
    }
  }
}
export function tabscomparisonSlider() {
  function sliderInit(){
    const slider = document.querySelector('.optimal_slider');
    const before = document.querySelector('.before');
    if(before){
        const beforeImage = before.querySelector('img');
        const change = document.querySelector('.change');
        const body = document.body;
        let isActive = false;
        let width = slider.offsetWidth;
        beforeImage.style.width = `${width}px`;
       

        change.addEventListener('mousedown', () => {
            isActive = true;
        })

        body.addEventListener('mouseup', () => {
            isActive = false;
        })

        body.addEventListener('mouseleave', () => {
            isActive = false;
        })

        const beforeAfterSlider = (x) => {
            let shift = Math.max(0, Math.min(x, slider.offsetWidth));
            before.style.width = `${shift}px`;
            change.style.left = `${shift}px`;
        };

        const pauseEvents = (e) => {
            e.stopPropagation();
            e.preventDefault();
            return false;
        };

        body.addEventListener('mousemove', (e) => {
            if (!isActive) {
                return;
            }

            let x = e.pageX;
            x -= slider.getBoundingClientRect().left;
            beforeAfterSlider(x);
            pauseEvents(e);
        })

        change.addEventListener('touchstart', () => {
            isActive = true;
        })

        body.addEventListener('touchend', () => {
            isActive = false;
        })

        body.addEventListener('touchcancel', () => {
            isActive = false;
        })

        body.addEventListener('touchmove', (e) => {
            if (!isActive) {
                return;
            }

            let x;
            let i;
            for (i = 0; i < e.changedTouches.length; i++) {
                x = e.changedTouches[i].pageX; 
            } 
            x -= slider.getBoundingClientRect().left;

            beforeAfterSlider(x);
            pauseEvents(e);
        })
    }
  }
  const parents = document.querySelectorAll('.optimal_switch');
   parents.forEach(item =>{
     if(item){
       const tabs = item.querySelectorAll('li');
       for(let i = 0; i < tabs.length; i++){
         let label = tabs[i].querySelector('label');
         label.addEventListener('click', function(){
           $(item).find('li').removeClass('active');
           $(tabs[i]).addClass('active');
           setTimeout(sliderInit, 1000);
         })
       }
     }
   });
}