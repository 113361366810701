
export function scrollProgress(){
    const animateElements = document.querySelectorAll('.progess-catalog');
    if(animateElements){
        let iteration = 0;
        if(iteration != 1){
            window.addEventListener('scroll',function(){
                
                let heightPage = document.documentElement.clientHeight + 60;
                console.log(heightPage);
                animateElements.forEach(item => {
                    let positionY = item.getBoundingClientRect().top;
                    if(positionY < heightPage){
                        item.classList.add('active');
                        iteration = 1;
                    }else{
                        item.classList.remove('active')
                        iteration = 0;
                    }
                })
            })
        }
    }
}
