export function twentytwenty() {
  if (document.querySelector('.twentytwenty-container')) {
    $(function () {
      $(".twentytwenty-container").twentytwenty({
        default_offset_pct: 0.5, // How much of the before image is visible when the page loads
        orientation: 'horizontal', // Orientation of the before and after images ('horizontal' or 'vertical')
        before_label: 'До', // Set a custom before label
        after_label: 'После', // Set a custom after label
        // move_slider_on_hover: true, // Move slider on mouse hover?
        // move_with_handle_only: true, // Allow a user to swipe anywhere on the image to control slider movement. 
        // click_to_move: false // Allow a user to click (or tap) anywhere on the image to move the slider to that location.
      });
    });
  }
}

export function twentytwenty3() {
  
}

export function twentytwenty4() {
  
}