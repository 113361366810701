import Swiper from 'swiper/swiper-bundle';

export function filtr() {
  
  const tags = {};
  const checks = document.querySelectorAll('.checkbox-label input');
  function swiperInit() {
    const swiper = new Swiper('.showcase-slider', {
      direction: 'horizontal',
      slidesPerView: 1,
      spaceBetween: 15,
      pagination: {
          el: ".swiper-pagination",
          type: 'bullets',
          clickable: true,
      },
    });
    return false; 
  }
  const addTag = (id, text) => {
    if (!(id in tags)) {
      const label = document.querySelector(`label[for="${id}"]`);
      if (label){
        label.classList.add("checkbox-label--active");
        //alert('click');
        setTimeout(swiperInit, 1000);
        
      }
      tags[id] = text.trim();
    }
  };

  const removeTag = (id) => {
    if (id in tags) {
      const label = document.querySelector(`label[for="${id}"]`);
      if (label){
        label.classList.remove("checkbox-label--active");
        setTimeout(swiperInit, 1000);
        //alert('un-click'); 
      }

      const checkbox = document.querySelector(`input[id="${id}"]`);
      if (checkbox) checkbox.checked = false;

      delete tags[id];
    }
  };

  const renderTags = () => {
    const filtrDoubl = document.querySelector('.filtr-double');
    filtrDoubl.innerHTML = '';

    for (let id in tags) {
      filtrDoubl.insertAdjacentHTML('beforeend', `<div class="filtr-double-added" data-id="${id}"><span>${tags[id]}</span><svg class="icon"><use xlink:href="assets/img/svg/symbol/sprite.svg#close-filter"></use></svg></div>`);
    }

    const tagsElements = filtrDoubl.querySelectorAll(".filtr-double div");

    tagsElements.forEach((item) => {
      item.addEventListener("click", function () {
        removeTag(this.getAttribute('data-id'));
        $('.closeFilter').click();
        renderTags();
      });
    });
  };
  document.querySelectorAll(".filtr__content").forEach((content) => {
    content.addEventListener('click', function(){
      this.classList.toggle("active");
    })
  })
  document.querySelectorAll(".filtr-list").forEach((button) => {
    button.addEventListener("click", function () {
      this.classList.toggle("filtr-list--active");
      const panel = this.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    });
  });

  checks.forEach((checkbox) => {
    checkbox.addEventListener("click", function () {
      const labelText = this.nextElementSibling.textContent;
      const id = this.id;

      if (this.checked) {
        addTag(id, labelText);
      } else {
        removeTag(id);
      }

      renderTags();
    });
  });
}