export function formPopup(){
    // const popup = document.querySelector('.form-popup');
    // const closePopup = document.querySelector('.form-popup-close');
    // const body = document.querySelector('body');

    // const formOpen = (e) => {
    //     if (e.target && e.target.classList.contains('form-open') || e.target.closest('.form-open')) {
    //         $(popup).addClass('active');
    //         $(body).addClass('overflow-hidden');
    //     }
    // };
    // document.addEventListener('click', formOpen);
    // if(closePopup){
    //     closePopup.addEventListener('click', function(){
    //         $(popup).removeClass('active');
    //         $(body).removeClass('overflow-hidden');
    //     })
    // }
    // window.onkeydown = function (event) {
    //     if (event.keyCode == 27) {
    //         $(popup).removeClass('active');
    //         $(body).removeClass('overflow-hidden');
    //         close = false;
    //     }
    // };
    // if(popup){
    //     if(popup.querySelector('.close-block')){
    //         popup.querySelector('.close-block').addEventListener('click', (e) => {
    //             popup.classList.remove('active');
    //             body.classList.remove('overflow-hidden');
    //         });
    //     }
    // }
    const body = document.querySelector('body');
    function formsInit(formClass, buttonClass){
        const form = document.querySelector(formClass);
        const closeForm = form.querySelector('.form-popup-close');
        document.addEventListener('click', (e) => {
            const target = e.target;
            if (target && target.classList.contains(buttonClass) || target.closest('.'+ buttonClass+'')) {
                e.preventDefault();
                const title = target.closest('.'+ buttonClass+'').dataset.name;
                const button = target.closest('.'+ buttonClass+'').dataset.button;
                const formTitle = form.querySelector('.popup-form-title');
                const input = form.querySelector('input[name="button"]');
                formTitle.innerHTML = title
                input.value = button;
                form.classList.add('active');
                window.onkeydown = function (event) {
                    if (event.keyCode == 27) {
                        $(form).removeClass('active');
                    }
                };
            }
        });
        closeForm.addEventListener('click', function(){
            $(form).removeClass('active');
        })
        
        if(form.querySelector('.close-block')){
            form.querySelector('.close-block').addEventListener('click', (e) => {
                form.classList.remove('active');
                body.classList.remove('overflow-hidden');
            });
        }
    }
    formsInit('.form-popup', 'form-open');
    //formsInit('.popup-contact', 'open-popup-contact');
    const formSuccess = document.querySelector('.popup_success');
    const closeformSuccess = formSuccess.querySelector('.form-popup-close');
    closeformSuccess.addEventListener('click', function(){
        $(formSuccess).removeClass('active');
    })
    window.onkeydown = function (event) {
        if (event.keyCode == 27) {
            $(formSuccess).removeClass('active');
        }
    };
    if(formSuccess.querySelector('.close-block')){
        formSuccess.querySelector('.close-block').addEventListener('click', (e) => {
            formSuccess.classList.remove('active');
            body.classList.remove('overflow-hidden');
        });
    }
}