export function mobileMenu(){
    const burger = document.querySelectorAll('.header__burger');
    const body = document.querySelector('main');
    burger.forEach(item => {
        item.addEventListener('click', function(){
            $(item).toggleClass('active');
            $('.desktop').toggleClass('active');
            $('.mobile-menu').toggleClass('active');
            window.onkeydown = function (event) {
                if (event.keyCode == 27) {
                    $(item).removeClass('active');
                    $('.desktop').removeClass('active');
                    $('.mobile-menu').removeClass('active');
                }
            };
            body.addEventListener('click', function(){
                $(item).removeClass('active');
                $('.desktop').removeClass('active');
                $('.mobile-menu').removeClass('active');
            })
        })
    });
}

