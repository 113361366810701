import Swiper from 'swiper/swiper-bundle';
export function modal() {

  // const overlay = document.querySelectorAll('.modal'),
  //   modalBody = document.querySelectorAll('.modal__body'),
  //   body = document.querySelector('body'),
  //   close = document.querySelector('.modal-close');


  // let modalToggle = false;

  // function bindModal(triggerSelector, modalSelector) {

  //   const trigger = document.querySelectorAll(triggerSelector),
  //     modal = document.querySelector(modalSelector),
  //     modalForm = document.querySelector('.modal__body');

  //   // Открытие модального окна
  //   trigger.forEach(item => {
  //     item.addEventListener('click', (e) => {
  //       if (e.target) {
  //         e.preventDefault();
  //       }
  //       modal.classList.add('active');
  //       modalForm.classList.add('active');
  //       body.classList.add('fix');
  //       modalToggle = true;
  //     });
  //   });

  //   // Закрытие модального окна по клику на подложку
  //   if (modal) {
  //     modal.addEventListener('click', (e) => {
  //       if (e.target === modal) {
  //         modal.classList.remove('active');
  //         modalForm.classList.remove('active');
  //         body.classList.remove('fix');
  //         modalToggle = false;
  //       }
  //     });
  //   }
  // }

  // // Функция закрытие модального окна
  // function closeModal() {
  //   overlay.forEach(item => {
  //     item.classList.remove('active');
  //   });
  //   modalBody.forEach(item => {
  //     item.classList.remove('active');
  //   });
  //   document.body.classList.remove('fix');
  // }

  // bindModal('.open-video-modal', '.modal--video');
  // bindModal('.modal-big__open', '.modal-big');
  // bindModal('.modal-mini__open', '.modal-mini');

  // // Закрытие модального окна по клику на кнопку
  // document.onkeydown = function (e) {
  //   if (e.keyCode == 27) {
  //     closeModal();
  //   }
  // };

  // // Закрытие модального окна по клику Esc
  // if (close) {
  //   close.addEventListener('click', () => {
  //     closeModal();
  //   });
  // }


}

export function potrfolioModal() {
  
  const openPortfolioModal = function (e) {
    const body = document.querySelector('body');
    let portfolioSliderThumb;
    let portfolioSlider;
    let filmsSliderMain;
    let filmsSliderPortfolio;
    let modalToggle = false;


    const openPopup =  function (e) {
        const portfolioId = e.target.closest('.portfolio-modal-open').dataset.modal;
        const modal = document.getElementById(`${portfolioId}`);
        const sliderThumbs = modal.querySelector('.pagination');
        const slider = modal.querySelector('.slider');

        const sliderFilms = modal.querySelector('.modal-section-slider');
        const sliderFilmsPortfolio = modal.querySelector('.modal-swiper');

        const allModals = document.querySelectorAll('.portfolio-inner');
        const tabs = modal.querySelectorAll('.modal-tab__switch');
        const content = modal.querySelectorAll('.modal-tab__content');
        const animateElementz = modal.querySelectorAll('.progess-modal');

        allModals.forEach(item => {
            item.classList.remove('active');
        });
        
        body.classList.add('overflow-hidden');
        modal.classList.add('active');

        if(slider) {
          portfolioSliderThumb = new Swiper(sliderThumbs, {
             loop: true,
             spaceBetween: 10,
             slidesPerView: 2,
             freeMode: true,
             watchSlidesProgress: true,
             breakpoints: {
              
              561: {
                slidesPerView: 3,
              },
              761: {
                slidesPerView: 4,
              },
              961: {
                slidesPerView: 5,
              }
            }
         });
         portfolioSlider = new Swiper(slider, {
             loop: true,
             spaceBetween: 10,
             navigation: {
                 nextEl: ".swiper-button-next",
                 prevEl: ".swiper-button-prev",
             },
             thumbs: {
                 swiper: portfolioSliderThumb,
             },
         });
        }
        if(sliderFilms){
          filmsSliderMain = new Swiper(sliderFilms, {
            direction: 'horizontal',
            slidesPerView: 1,
            spaceBetween: 15,
            loop: true,
            pagination: {
                el: ".swiper-pagination",
                type: 'bullets',
                clickable: true,
            },
          });
        }
        if(sliderFilmsPortfolio){
          filmsSliderPortfolio= new Swiper(sliderFilmsPortfolio, {
            direction: 'horizontal',
            loop: true,
            slidesPerView: 1,
            spaceBetween: 20,
            breakpoints: {
            
                761: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                },
                1141: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                }
            },
            navigation: {
                nextEl: '.modal-button-next',
                prevEl: '.modal-button-prev',
            },
            pagination: {
                el: ".swiper-pagination",
                type: 'bullets',
                clickable: true,
            },
          });
        }
        if(tabs && content){
          for(let i = 0; i < tabs.length; i++){  
            if(tabs[i]){
              tabs[i].addEventListener('click', function(){
                $(modal).find('.modal-tab__switch').removeClass('active');
                $(modal).find('.modal-tab__content').removeClass('active');
                // $('.modal-tab__switch').removeClass('active');
                // $('.modal-tab__content').removeClass('active');
                $(tabs[i]).addClass('active');
                $(content[i]).addClass('active');
              })
            }
          }
        }
        if (modal.querySelector('#container3')) {
          $(function () {
            $("#container3").twentytwenty({
              default_offset_pct: 0.5, // How much of the before image is visible when the page loads
              orientation: 'horizontal', // Orientation of the before and after images ('horizontal' or 'vertical')
              before_label: 'До', // Set a custom before label
              after_label: 'После', // Set a custom after label
              // move_slider_on_hover: true, // Move slider on mouse hover?
              // move_with_handle_only: true, // Allow a user to swipe anywhere on the image to control slider movement. 
              // click_to_move: false // Allow a user to click (or tap) anywhere on the image to move the slider to that location.
            });
          });
        }
        if (modal.querySelector('#container4')) {
          $(function () {
            $("#container4").twentytwenty({
              default_offset_pct: 0.5, // How much of the before image is visible when the page loads
              orientation: 'horizontal', // Orientation of the before and after images ('horizontal' or 'vertical')
              before_label: 'До', // Set a custom before label
              after_label: 'После', // Set a custom after label
              // move_slider_on_hover: true, // Move slider on mouse hover?
              // move_with_handle_only: true, // Allow a user to swipe anywhere on the image to control slider movement. 
              // click_to_move: false // Allow a user to click (or tap) anywhere on the image to move the slider to that location.
            });
          });
        }



        if(animateElementz){
          animateElementz.forEach(item => {
            item.classList.add('active');
          });
        }







        const lazyImg = modal.querySelectorAll('[data-lazy-src]');
        lazyImg.forEach(item => {
            item.attributes[0].value = item.dataset.lazySrc;
        });

        modalToggle = true;
    };

    const closePopup = function () {
        const modals = document.querySelectorAll('.portfolio-inner');
        const animateElementz = modal.querySelectorAll('.progess-modal');
          animateElementz.forEach(item => {
            item.classList.remove('active');
          });
        
        modals.forEach(item => {
            item.classList.remove('active');
        });
        document.body.classList.remove('overflow-hidden');
        if(filmsSliderPortfolio){
          filmsSliderPortfolio.destroy(true, true);
        }
        if(filmsSliderMain){
          filmsSliderMain.destroy(true, true);
        }
        if(portfolioSlider) {
          portfolioSlider.destroy(true, true);
          portfolioSliderThumb.destroy(true, true);
        }
        modalToggle = false;
    };

    openPopup(e);

    window.onkeydown = function (event) {
        if (event.keyCode == 27 && modalToggle) {
            closePopup();
        }
    };
    const portfolioId = e.target.closest('.portfolio-modal-open').dataset.modal;
    const modal = document.getElementById(`${portfolioId}`);
    modal.querySelector('.close-block').addEventListener('click', (e) => {
      closePopup();
    });
    document.addEventListener('click', (e) => {
        if(e.target.classList.contains('portfolio-inner_close') || e.target.closest('.portfolio-inner_close') || e.target.classList.contains('portfolio-inner') ) {
            closePopup();
        }
    });
};

document.addEventListener('click', (e) => {
    if (e.target.classList.contains('portfolio-modal-open') || e.target.closest('.portfolio-modal-open')) {
        e.preventDefault();
        openPortfolioModal(e);
    }
});












  
}