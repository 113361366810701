import Swiper from 'swiper/swiper-bundle';

export function complexitySlider() {
    const swiper = new Swiper(".complexity-swiper", {
        direction: 'horizontal',
        pagination: {
            el: ".swiper-pagination",
            type: 'bullets',
            clickable: true,
        },

        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            560: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            760: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
            960: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            1140: {
                slidesPerView: 8,
                spaceBetween: 30,
            },
            1340: {
                slidesPerView: 8,
                spaceBetween: 10,
            }
        }
    });
}

export function whereSlider() {
    const swiper = new Swiper(".where-swiper", {
        direction: 'horizontal',
        pagination: {
            el: ".swiper-pagination",
            type: 'bullets',
            clickable: true,
        },

        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 40,
            },
            560: {
                slidesPerView: 2,
            },
            760: {
                slidesPerView: 3,
                spaceBetween: 15,
            },
            960: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
            1140: {
                slidesPerView: 6,
            }
        }
    });
}

export function filmsSlider() {
    const swiper = new Swiper(".films-swiper", {
        direction: 'horizontal',
        pagination: {
            el: ".swiper-pagination",
            type: 'bullets',
            clickable: true,
        },

        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 40,
            },
            560: {
                slidesPerView: 2,
            },
            760: {
                slidesPerView: 3,
                spaceBetween: 15,
            },
            960: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
            1140: {
                slidesPerView: 6,
            }
        }
    });
}

export function manufacturerSlider() {
    const swiper = new Swiper(".manufacturer-slider", {
        direction: 'horizontal',
        autoplay: {
            delay: 3000,
        },
        navigation: {
            nextEl: '.manufacturer--next',
            prevEl: '.manufacturer--prev',
        },

        pagination: {
            el: ".swiper-pagination",
            type: 'bullets',
            clickable: true,
        },

        breakpoints: {
            320: {
                slidesPerView: 2,
                spaceBetween: 40,
            },
            560: {
                slidesPerView: 2,
            },
            960: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
            1140: {
                slidesPerView: 4,
            }
        }
    });
}

export function teamSlider() {
    const swiper = new Swiper(".team-slider", {
        direction: 'horizontal',
        navigation: {
            nextEl: '.team-button-next',
            prevEl: '.team-button-prev',
        },
        pagination: {
            el: ".swiper-pagination",
            type: 'bullets',
            clickable: true,
        },

        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 10,

            },
            560: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            760: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            960: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            1140: {
                slidesPerView: 3,
                spaceBetween: 25,
            }
        }
    });
}

export function ourWorkSlider() {
    const swiper = new Swiper(".ourWork-slider", {
        direction: 'horizontal',
        autoplay: {
            delay: 3000,
        },
        navigation: {
            nextEl: '.ourWork--next',
            prevEl: '.ourWork--prew',
        },
        pagination: {
            el: ".swiper-pagination",
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 40,
            },
            1141: {
                slidesPerView: 2,
                spaceBetween: 60,
            }
        }
    });
}

export function certificatesSlider() {
    const swiper = new Swiper(".certificates-slider", {
        direction: 'horizontal',
        autoplay: {
            delay: 3000,
        },
        navigation: {
            nextEl: '.certificates--next',
            prevEl: '.certificates--prew',
        },
        pagination: {
            el: ".swiper-pagination",
            type: 'bullets',
            clickable: true,
        },

        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            560: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            760: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            960: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            1140: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            1340: {
                slidesPerView: 4,
                spaceBetween: 30,
            }
        }
    });
}

export function reviewsSlider() {
    const swiper = new Swiper(".reviews-slider", {
        direction: 'horizontal',
        slidesPerView: 1,
        spaceBetween: 40,
        autoplay: {
            delay: 3000,
        },
        navigation: {
            nextEl: '.reviews_next',
            prevEl: '.reviews_prev',
        },
        pagination: {
            el: ".swiper-pagination",
            type: 'bullets',
            clickable: true,
        },

        breakpoints: {
            1140: {
                slidesPerView: 2,
            }
        }
    });
}

export function clientsSlider() {
    const swiper = new Swiper(".clients-slider", {
        direction: 'horizontal',
        autoplay: {
            delay: 3000,
        },
        navigation: {
            nextEl: '.clients_next',
            prevEl: '.clients_prev',
        },
        pagination: {
            el: ".swiper-pagination",
            type: 'bullets',
            clickable: true,
        },

        breakpoints: {
            320: {
                slidesPerView: 2,
                spaceBetween: 40,
            },
            560: {
                slidesPerView: 2,
            },
            760: {
                slidesPerView: 2,
                spaceBetween: 15,
            },
            1140: {
                slidesPerView: 4,
            }
        }
    });
}

export function articlesSlider() {
    const swiper = new Swiper(".articles .swiper", {
        direction: 'horizontal',
        slidesPerView: 1,
        spaceBetween: 20,
        pagination: {
            el: ".swiper-pagination",
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            960: {
                slidesPerView: 2,
                spaceBetween: 40,
            }
        }
    });
}

export function newsSlider() {
    const swiper = new Swiper(".news .swiper", {
        direction: 'horizontal',
        slidesPerView: 1,
        pagination: {
            el: ".swiper-pagination",
            type: 'bullets',
            clickable: true,
        },

        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            681: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            1341: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
            
        }
    });
}

export function showcaseSlider() {
    const swiper = new Swiper('.showcase-slider', {
        direction: 'horizontal',
        slidesPerView: 1,
        spaceBetween: 15,
        loop: true,
        pagination: {
            el: ".swiper-pagination",
            type: 'bullets',
            clickable: true,
        },
        });  
}

export function modalSectionSliderInner() {
    const parents = document.querySelector('.films-inner');
    if(parents){
        const sliderFilms = parents.querySelector('.modal-section-slider');
        const swiper = new Swiper(sliderFilms, {
            direction: 'horizontal',
            slidesPerView: 1,
            spaceBetween: 15,
            pagination: {
                el: ".swiper-pagination",
                type: 'bullets',
                clickable: true,
            },
        });
    }
}

export function modalSliderInner() {
    const parents = document.querySelector('.films-inner');
    if(parents){
        const sliderFilmsPortfolio = parents.querySelector('.modal-swiper');
        const swiper = new Swiper(sliderFilmsPortfolio, {
        direction: 'horizontal',
        slidesPerView: 1,
        spaceBetween: 20,
        breakpoints: {
        
            761: {
                slidesPerView: 2,
                spaceBetween: 40,
            },
            1141: {
                slidesPerView: 3,
                spaceBetween: 40,
            }
        },
        navigation: {
            nextEl: '.modal-button-next',
            prevEl: '.modal-button-prev',
        },
        pagination: {
            el: ".swiper-pagination",
            type: 'bullets',
            clickable: true,
        },
        });
    }
}

export function portfolioInnerSlider() {
    const pagination = new Swiper('.portfolio-inner-page .pagination', {
        // loop: true,
        spaceBetween: 10,
        slidesPerView: 2,
        freeMode: true,
        watchSlidesProgress: true,
        breakpoints: {
        
        561: {
            slidesPerView: 3,
        },
        761: {
            slidesPerView: 4,
        },
        961: {
            slidesPerView: 5,
        }
        }
    });
    const slider = new Swiper('.portfolio-inner-page .slider', {
        //loop: true,
        spaceBetween: 10,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        thumbs: {
            swiper: pagination,
        },
    });
}

export function otherWorkSlider() {
    const swiper = new Swiper(".other-work .swiper", {
        direction: 'horizontal',
        slidesPerView: 1,
        spaceBetween: 20,
        navigation: {
            nextEl: '.other-work_next',
            prevEl: '.other-work_prev',
        },
        pagination: {
            el: ".swiper-pagination",
            type: 'bullets',
            clickable: true,
        },

        breakpoints: {
            
            600: {
                slidesPerView: 2,
                spaceBetween: 40,
            },
            760: {
                slidesPerView: 3,
                spaceBetween: 15,
            },
            1140: {
                spaceBetween: 40,
                slidesPerView: 4,
            }
        }
    });
}