export function scrollHeader() {
  $(window).scroll(function () {
    if ($(this).scrollTop() > 176) {
      $('.header-sticky').addClass('scroll');
    } else {
      $('.header-sticky').removeClass('scroll');
    }
  });
  $(window).on("scroll", function () {
    //$('.header-sticky').toggleClass("scroll", $(this).scrollTop() > $(window).height());
  });
}