import $ from "jquery";
import svg4everybody from "svg4everybody";
import baguetteBox from "baguettebox.js";

import {
    complexitySlider,
    whereSlider,
    filmsSlider,
    manufacturerSlider,
    teamSlider,
    ourWorkSlider,
    certificatesSlider,
    reviewsSlider,
    clientsSlider,
    articlesSlider,
    newsSlider,
    showcaseSlider,
    modalSectionSliderInner,
    modalSliderInner,
    portfolioInnerSlider,
    otherWorkSlider,
} from "./modules/sliders";

import {
    scrollHeader
} from "./modules/scroll-header";

import {
    openHiddenMenu
} from "./modules/hidden-menu";

import {
    mobileMenu
} from "./modules/mobileMenu";

import {
    FAQ
} from "./modules/faq";

import {
    modal,
    potrfolioModal
} from "./modules/modal";

import {
    maskForm
} from "./modules/mask-form";

import {
    filtr
} from "./modules/filtr";

import {
    twentytwenty,
    twentytwenty3,
    twentytwenty4
} from "./modules/twentytwenty";

import {
    tabs,
    tabsTintingOffices,
    tabsPortfolio,
    tabsModalInner,
    tabscomparisonSlider
} from "./modules/tabs";

import {
    quiz,
    checkFields
} from "./modules/quiz";

import {
    formPopup,
} from "./modules/form-popup";

import {
    comparisonSlider,
} from "./modules/comparisonSlider";

import {
    up,
} from "./modules/up";

import {
    scrollProgress,
} from "./modules/scrollProgress";

import {
    sliderFilter,
} from "./modules/sliderFilter";

$(function () {
    svg4everybody();
    // checkWebp();
    openHiddenMenu();
    mobileMenu();
    complexitySlider();
    whereSlider();
    scrollHeader();
    filmsSlider();
    manufacturerSlider();
    teamSlider();
    ourWorkSlider();
    certificatesSlider();
    reviewsSlider();
    clientsSlider();
    FAQ();
    articlesSlider();
    newsSlider();
    showcaseSlider();
    modal();
    checkFields();
    maskForm();
    filtr();
    twentytwenty();
    twentytwenty3();
    twentytwenty4();
    tabs();
    quiz();
    potrfolioModal();
    portfolioInnerSlider();
    tabsTintingOffices();
    tabsPortfolio();
    otherWorkSlider();
    formPopup();
    comparisonSlider();
    tabsModalInner();
    modalSectionSliderInner();
    modalSliderInner();
    tabscomparisonSlider();
    up();
    scrollProgress();
    sliderFilter();
});
