export function openHiddenMenu() {
  const headerMenu = document.querySelector('.header__filmMenu');
  const body = document.querySelector('main');
  headerMenu.addEventListener('click', function(){
    $('.header__catalog-menu').toggleClass('active');
    $('.open').toggleClass('active');
    $('.close').toggleClass('active');
    window.onkeydown = function (event) {
      if (event.keyCode == 27) {
        $('.header__catalog-menu').removeClass('active');
        $('.open').addClass('active');
        $('.close').removeClass('active');
      }
    };
    body.addEventListener('click', function(){
      $('.header__catalog-menu').removeClass('active');
      $('.open').addClass('active');
      $('.close').removeClass('active');
    })
  })
}