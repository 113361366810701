export function maskForm(){
  $('.form-phone').mask('+7(999) 999-99-99');

    // Разрешаем ввод в поле name только буквы
  $('[name=name]').bind("change keyup input click", function() {
    if (this.value.match(/[^а-яА-Яa-zA-Z\s]/g)) {
    this.value = this.value.replace(/[^а-яА-Яa-zA-Z\s]/g, '');
    }
  });
}
