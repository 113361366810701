export function sliderFilter(){
    function transliter( str ) {
    
        const ru = {
            'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 
            'е': 'e', 'ё': 'e', 'ж': 'j', 'з': 'z', 'и': 'i', 
            'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o', 
            'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u', 
            'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch', 'ш': 'sh', 
            'щ': 'shch', 'ы': 'y', 'э': 'e', 'ю': 'u', 'я': 'ya', ' ': '-'
        }, n_str = [];
        
        str = str.replace(/[ъь]+/g, '').replace(/й/g, 'i');
        
        for ( var i = 0; i < str.length; ++i ) {
           n_str.push(
                  ru[ str[i] ]
               || ru[ str[i].toLowerCase() ] == undefined && str[i]
               || ru[ str[i].toLowerCase() ].replace(/^(.)/, function ( match ) { return match.toUpperCase() })
           );
        }
        
        return n_str.join('');
    }
    
    const outputContent = document.querySelector('.optimal_cnt');
    if(outputContent){
        const parentType = document.querySelector('.type');
        let elementType = parentType.querySelectorAll('li');
        $(elementType[0]).addClass('active');
        let contentType = transliter(elementType[0].innerHTML.toLowerCase().replace('%','').replace('<a>','').replace('</a>',''));
        
        const parentColor = document.querySelector('.color');
        let elementColor = parentColor.querySelectorAll('li');
        $(elementColor[0]).addClass('active');
        let contentColor = transliter(elementColor[0].innerHTML.toLowerCase().replace('%','').replace('<a>','').replace('</a>',''));
        
        const parentDarken = document.querySelector('.darken');
        let elementDarken = parentDarken.querySelectorAll('li');
        $(elementDarken[0]).addClass('active');
        let contentDarken = transliter(elementDarken[0].innerHTML.toLowerCase().replace('%','').replace('<a>','').replace('</a>',''));
        
        let resultId = [contentType, contentColor, contentDarken];

        
        let outputId = resultId[0] +'_'+ resultId[1] +'_'+ resultId[2];
        
        const view = outputContent.querySelector('.optimal_view');
        const viewItem = view.querySelectorAll('li');

        function init(){
            $(outputContent).find('#'+outputId).addClass('active');
            $(outputContent).find('#'+outputId).addClass('imgCurrent');
            const currentItem = outputContent.querySelector('.imgCurrent');
            //console.log(currentItem);
            $(viewItem[1]).removeClass('active')
            $(viewItem[0]).addClass('active');
            const lazyImg = document.querySelectorAll('[data-lazy-src]');
            
            lazyImg.forEach(img => {
                img.attributes[0].value = img.dataset.lazySrc;
            });
        }
        function receivingId(){
            outputBlock.forEach(items => {
                let outputId = transliter(items.id.toLowerCase());
                items.id = outputId;
                if(items.id.includes(resultId[0])){
                    
                    elementColor.forEach(color => {
                        let availableColor = transliter(color.innerHTML.toLowerCase().replace('%','').replace('<a>','').replace('</a>',''));
                        if(items.id.includes(availableColor)){
                            
                            $(color).addClass('true');
                            elementDarken.forEach(darken => {
                                let availableDarken = transliter(darken.innerHTML.toLowerCase().replace('%','').replace('<a>','').replace('</a>',''));
                                let res1 = items.id.replace(/[0-9]/g, '').slice(0,-1);
                                let res2 = res1.split('_').pop();
  

                               if(res2 == resultId[1]){
                               
                                let idD = parseInt(items.id.match(/\d+/));
                                   
                                    if(idD == availableDarken){
                                     
                                        $(darken).addClass('true');
                                        
                                    }
                                 }
                               
                                
                            });
                        }
                    });
                }
            });
        }



        elementType.forEach(item => {
            item.addEventListener('click', e => {
                $(parentType).find('li').removeClass('active');
                $(item).addClass('active');
                contentType = transliter(e.target.innerHTML.toLowerCase().replace('%','').replace('<a>','').replace('</a>',''));

                resultId[0] = contentType;
                outputId = resultId[0] +'_'+ resultId[1] +'_'+ resultId[2];
                $(outputContent).find('.custom_optimal_fileter').removeClass('active');
                $(outputContent).find('.custom_optimal_fileter').removeClass('imgCurrent');
                $(parentColor).find('li').removeClass('true');
                $(parentDarken).find('li').removeClass('true');

                
                
                receivingId()
               

                const activeColor = parentColor.querySelectorAll('.true');
                let contentColors = transliter(activeColor[0].innerHTML.toLowerCase().replace('%','').replace('<a>','').replace('</a>',''));
                resultId[1] = contentColors;
                outputId = resultId[0] +'_'+ resultId[1] +'_'+ resultId[2];
                console.log(activeColor[0]);
                console.log(outputId);
                $(parentColor).find('li').removeClass('active');
                $(activeColor[0]).addClass('active')

                receivingId()

                const activeDarken = parentDarken.querySelectorAll('.true');
                console.log(activeDarken[0]);
                let contentDarkens = transliter(activeDarken[0].innerHTML.toLowerCase().replace('%','').replace('<a>','').replace('</a>',''));
                resultId[2] = contentDarkens;
                outputId = resultId[0] +'_'+ resultId[1] +'_'+ resultId[2];
                console.log(activeDarken[0]);
                console.log(outputId);
                $(parentDarken).find('li').removeClass('active');
                $(activeDarken[0]).addClass('active')

                receivingId()
                init();
            })
        });
        
        
        elementColor.forEach(item => {
            item.addEventListener('click', e => {
                $(parentColor).find('li').removeClass('active');
                $(item).addClass('active');
                contentColor = transliter(e.target.innerHTML.toLowerCase().replace('%','').replace('<a>','').replace('</a>',''));
                resultId[1] = contentColor;
                outputId = resultId[0] +'_'+ resultId[1] +'_'+ resultId[2];
                $(outputContent).find('.custom_optimal_fileter').removeClass('active');
                $(outputContent).find('.custom_optimal_fileter').removeClass('imgCurrent');
                $(parentDarken).find('li').removeClass('true');

                receivingId()
               

               
                
                const activeDarken = parentDarken.querySelectorAll('.true');
                console.log(activeDarken[0]);
                let contentDarkens = transliter(activeDarken[0].innerHTML.toLowerCase().replace('%','').replace('<a>','').replace('</a>',''));
                resultId[2] = contentDarkens;
                outputId = resultId[0] +'_'+ resultId[1] +'_'+ resultId[2];
                console.log(activeDarken[0]);
                console.log(outputId);
                $(parentDarken).find('li').removeClass('active');
                $(activeDarken[0]).addClass('active')

                receivingId()
                init();
            })
        });
        
        
        elementDarken.forEach(item => {
            item.addEventListener('click', e => {
                $(parentDarken).find('li').removeClass('active');
                $(item).addClass('active');
                contentDarken = transliter(e.target.innerHTML.toLowerCase().replace('%','').replace('<a>','').replace('</a>',''));
                resultId[2] = contentDarken;
                outputId = resultId[0] +'_'+ resultId[1] +'_'+ resultId[2];
                $(outputContent).find('.custom_optimal_fileter').removeClass('active');
                $(outputContent).find('.custom_optimal_fileter').removeClass('imgCurrent');
                receivingId()
                init();
            })
        });
        const outputBlock = document.querySelectorAll('.custom_optimal_fileter');
        receivingId();
        init();
    }
}